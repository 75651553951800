import axios from "axios";
import { BASE_URL } from "../../Base_URL/BaseURL";
import {
  ADD_BYPASS_FAILURE,
  ADD_BYPASS_REQUEST,
  ADD_BYPASS_SUCCESS,
  ADD_PERMISSIONS_FAILURE,
  ADD_PERMISSIONS_REQUEST,
  ADD_PERMISSIONS_SUCCESS,
  ADD_PREDEFINED_PROBLEMS_FAILURE,
  ADD_PREDEFINED_PROBLEMS_REQUEST,
  ADD_PREDEFINED_PROBLEMS_SUCCESS,
  ADD_TOTE_FAILURE,
  ADD_TOTE_REQUEST,
  ADD_TOTE_SUCCESS,
  APPROVE_REJECT_MARK_LOST_FAILURE,
  APPROVE_REJECT_MARK_LOST_REQUEST,
  APPROVE_REJECT_MARK_LOST_SUCCESS,
  ASSIGN_PICKLIST_TO_USERS_FAILURE,
  ASSIGN_PICKLIST_TO_USERS_REQUEST,
  ASSIGN_PICKLIST_TO_USERS_SUCCESS,
  ASSIGN_SECONDARY_ORDERS_PICKLIST_FAILURE,
  ASSIGN_SECONDARY_ORDERS_PICKLIST_REQUEST,
  ASSIGN_SECONDARY_ORDERS_PICKLIST_SUCCESS,
  BYPASS_ALL_ORDER_FAILURE,
  BYPASS_ALL_ORDER_REQUEST,
  BYPASS_ALL_ORDER_SUCCESS,
  CANCEL_PICKLIST_FAILURE,
  CANCEL_PICKLIST_REQUEST,
  CANCEL_PICKLIST_SUCCESS,
  CHANGE_PICKLIST_STATUS_FAILURE,
  CHANGE_PICKLIST_STATUS_REQUEST,
  CHANGE_PICKLIST_STATUS_SUCCESS,
  CLEAR_ERRORS,
  COMPLETE_AUDIT_FAILURE,
  COMPLETE_AUDIT_REQUEST,
  COMPLETE_AUDIT_SUCCESS,
  CREATE_PICKLIST_REQUIREMENT_FAILURE,
  CREATE_PICKLIST_REQUIREMENT_REQUEST,
  CREATE_PICKLIST_REQUIREMENT_SUCCESS,
  DELETE_PREDEFINED_PROBLEMS_FAILURE,
  DELETE_PREDEFINED_PROBLEMS_REQUEST,
  DELETE_PREDEFINED_PROBLEMS_SUCCESS,
  DELETE_TOTE_FAILURE,
  DELETE_TOTE_REQUEST,
  DELETE_TOTE_SUCCESS,
  EDIT_PREDEFINED_PROBLEMS_FAILURE,
  EDIT_PREDEFINED_PROBLEMS_REQUEST,
  EDIT_PREDEFINED_PROBLEMS_SUCCESS,
  GET_ALL_ASSIGNEE_FOR_PICKLIST_FAILURE,
  GET_ALL_ASSIGNEE_FOR_PICKLIST_REQUEST,
  GET_ALL_ASSIGNEE_FOR_PICKLIST_SUCCESS,
  GET_ALL_COMMUNICATION_ALERT_LIST_COUNT_FAILURE,
  GET_ALL_COMMUNICATION_ALERT_LIST_COUNT_REQUEST,
  GET_ALL_COMMUNICATION_ALERT_LIST_COUNT_SUCCESS,
  GET_ALL_COMMUNICATION_ALERT_LIST_FAILURE,
  GET_ALL_COMMUNICATION_ALERT_LIST_REQUEST,
  GET_ALL_COMMUNICATION_ALERT_LIST_SUCCESS,
  GET_ALL_FILTERS_PICKING_MODULE_FAILURE,
  GET_ALL_FILTERS_PICKING_MODULE_REQUEST,
  GET_ALL_FILTERS_PICKING_MODULE_SUCCESS,
  GET_ALL_MULTI_ORDER_LABEL_GENERATOR_FAILURE,
  GET_ALL_MULTI_ORDER_LABEL_GENERATOR_REQUEST,
  GET_ALL_MULTI_ORDER_LABEL_GENERATOR_SUCCESS,
  GET_ALL_PREDEFINED_PROBLEMS_FAILURE,
  GET_ALL_PREDEFINED_PROBLEMS_REQUEST,
  GET_ALL_PREDEFINED_PROBLEMS_SUCCESS,
  GET_ALL_SINGLE_ORDER_LABEL_GENERATOR_FAILURE,
  GET_ALL_SINGLE_ORDER_LABEL_GENERATOR_REQUEST,
  GET_ALL_SINGLE_ORDER_LABEL_GENERATOR_SUCCESS,
  GET_COURIER_WISE_TABLE_DATA_FAILURE,
  GET_COURIER_WISE_TABLE_DATA_REQUEST,
  GET_COURIER_WISE_TABLE_DATA_SUCCESS,
  GET_DASHBOARD_ALL_DATA_FAILURE,
  GET_DASHBOARD_ALL_DATA_REQUEST,
  GET_DASHBOARD_ALL_DATA_SUCCESS,
  GET_DASHBOARD_USERS_FAILURE,
  GET_DASHBOARD_USERS_REQUEST,
  GET_DASHBOARD_USERS_SUCCESS,
  GET_DEVICE_MANAGEMENT_DATA_FAILURE,
  GET_DEVICE_MANAGEMENT_DATA_REQUEST,
  GET_DEVICE_MANAGEMENT_DATA_SUCCESS,
  GET_LOST_SUB_LOCATION_LIST_TABLE_DATA_FAILURE,
  GET_LOST_SUB_LOCATION_LIST_TABLE_DATA_REQUEST,
  GET_LOST_SUB_LOCATION_LIST_TABLE_DATA_SUCCESS,
  GET_MARK_LOST_COUNT_FAILURE,
  GET_MARK_LOST_COUNT_REQUEST,
  GET_MARK_LOST_COUNT_SUCCESS,
  GET_ORDER_LIST_TABLE_DATA_FAILURE,
  GET_ORDER_LIST_TABLE_DATA_REQUEST,
  GET_ORDER_LIST_TABLE_DATA_SUCCESS,
  GET_PICKER_ACTIVITY_TABLE_DATA_FAILURE,
  GET_PICKER_ACTIVITY_TABLE_DATA_REQUEST,
  GET_PICKER_ACTIVITY_TABLE_DATA_SUCCESS,
  GET_PICKER_MANAGEMENT_TABLE_DATA_FAILURE,
  GET_PICKER_MANAGEMENT_TABLE_DATA_REQUEST,
  GET_PICKER_MANAGEMENT_TABLE_DATA_SUCCESS,
  GET_PICKING_COUNT_AFTER_LOST__FAILURE,
  GET_PICKING_COUNT_AFTER_LOST__REQUEST,
  GET_PICKING_COUNT_AFTER_LOST__SUCCESS,
  GET_PICKLIST_MANAGEMENT_TABLE_DATA_FAILURE,
  GET_PICKLIST_MANAGEMENT_TABLE_DATA_REQUEST,
  GET_PICKLIST_MANAGEMENT_TABLE_DATA_SUCCESS,
  GET_PICKLIST_VIEW_DETAILS_FAILURE,
  GET_PICKLIST_VIEW_DETAILS_REQUEST,
  GET_PICKLIST_VIEW_DETAILS_SUCCESS,
  GET_PORTAL_WISE_TABLE_DATA_FAILURE,
  GET_PORTAL_WISE_TABLE_DATA_REQUEST,
  GET_PORTAL_WISE_TABLE_DATA_SUCCESS,
  GET_SECONDARY_PICKLIST_TABLE_DATA_FAILURE,
  GET_SECONDARY_PICKLIST_TABLE_DATA_REQUEST,
  GET_SECONDARY_PICKLIST_TABLE_DATA_SUCCESS,
  GET_SETTINGS_FAILURE,
  GET_SETTINGS_REQUEST,
  GET_SETTINGS_SUCCESS,
  GET_TOTE_LIST_FAILURE,
  GET_TOTE_LIST_REQUEST,
  GET_TOTE_LIST_SUCCESS,
  LIST_CREATE_ORDER_DETAILS_FAILURE,
  LIST_CREATE_ORDER_DETAILS_REQUEST,
  LIST_CREATE_ORDER_DETAILS_SUCCESS,
  MARK_LOST_SYNC_FAILURE,
  MARK_LOST_SYNC_REQUEST,
  MARK_LOST_SYNC_SUCCESS,
  MARK_LOST_TABLE_DATA_FAILURE,
  MARK_LOST_TABLE_DATA_REQUEST,
  MARK_LOST_TABLE_DATA_SUCCESS,
  MARKED_PLACED_MULTI_LABEL_GENERATOR_FAILURE,
  MARKED_PLACED_MULTI_LABEL_GENERATOR_REQUEST,
  MARKED_PLACED_MULTI_LABEL_GENERATOR_SUCCESS,
  MARKED_PLACED_SINGLE_LABEL_GENERATOR_FAILURE,
  MARKED_PLACED_SINGLE_LABEL_GENERATOR_REQUEST,
  MARKED_PLACED_SINGLE_LABEL_GENERATOR_SUCCESS,
  PICKLIST_ANOTHER_DEPTH_DETAILS_FAILURE,
  PICKLIST_ANOTHER_DEPTH_DETAILS_REQUEST,
  PICKLIST_ANOTHER_DEPTH_DETAILS_SUCCESS,
  PRINT_NOT__FOUND__ORDER__LABEL__FAILURE,
  PRINT_NOT__FOUND__ORDER__LABEL__REQUEST,
  PRINT_NOT__FOUND__ORDER__LABEL__SUCCESS,
  READ_COMMUNICATION_MODAL_FAILURE,
  READ_COMMUNICATION_MODAL_REQUEST,
  READ_COMMUNICATION_MODAL_SUCCESS,
  REASSIGN_PICKLIST_TO_USERS_FAILURE,
  REASSIGN_PICKLIST_TO_USERS_REQUEST,
  REASSIGN_PICKLIST_TO_USERS_SUCCESS,
  REASSIGN_SECONDARY_ORDERS_PICKLIST_FAILURE,
  REASSIGN_SECONDARY_ORDERS_PICKLIST_REQUEST,
  REASSIGN_SECONDARY_ORDERS_PICKLIST_SUCCESS,
  REMOVE_BYPASS_FAILURE,
  REMOVE_BYPASS_REQUEST,
  REMOVE_BYPASS_SUCCESS,
  SCAN_AUDIT_FAILURE,
  SCAN_AUDIT_REQUEST,
  SCAN_AUDIT_SUCCESS,
  SECONDARY_ORDER_DETAILS_FAILURE,
  SECONDARY_ORDER_DETAILS_REQUEST,
  SECONDARY_ORDER_DETAILS_SUCCESS,
  SETTINGS_FAILURE,
  SETTINGS_REQUEST,
  SETTINGS_SUCCESS,
  START_AUDIT_FAILURE,
  START_AUDIT_REQUEST,
  START_AUDIT_SUCCESS,
  SYNC_NOT_FOUND_MARK_LOST_FAILURE,
  SYNC_NOT_FOUND_MARK_LOST_REQUEST,
  SYNC_NOT_FOUND_MARK_LOST_SUCCESS,
  VACANT_TOTE_FAILURE,
  VACANT_TOTE_REQUEST,
  VACANT_TOTE_SUCCESS,
} from "../../Constants/PickerMgmt/PickerManagementConstants";
import {
  GET_PICKER_VIEW_DETAILS_FAILURE,
  GET_PICKER_VIEW_DETAILS_REQUEST,
  GET_PICKER_VIEW_DETAILS_SUCCESS,
} from "../../Constants/OrderHandoverConstants/GetCourierNameConstants";

export const getPickerManagementTable =
  (service_name, page, token, per_page) => async (dispatch) => {
    try {
      dispatch({
        type: GET_PICKER_MANAGEMENT_TABLE_DATA_REQUEST,
      });

      const { data } = await axios.post(`${BASE_URL}`, {
        service_name,
        page,
        token,
        per_page,
      });
      dispatch({
        type: GET_PICKER_MANAGEMENT_TABLE_DATA_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: GET_PICKER_MANAGEMENT_TABLE_DATA_FAILURE,
        payload: error.message,
      });
    }
  };

export const getPicklistManagementTable =
  (service_name, page, token, limit) => async (dispatch) => {
    try {
      dispatch({
        type: GET_PICKLIST_MANAGEMENT_TABLE_DATA_REQUEST,
      });

      const { data } = await axios.post(`${BASE_URL}`, {
        service_name,
        page,
        token,
        limit,
      });
      dispatch({
        type: GET_PICKLIST_MANAGEMENT_TABLE_DATA_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: GET_PICKLIST_MANAGEMENT_TABLE_DATA_FAILURE,
        payload: error.message,
      });
    }
  };

export const getSecondaryPicklistManagementTable =
  (service_name, page, token, limit) => async (dispatch) => {
    try {
      dispatch({
        type: GET_SECONDARY_PICKLIST_TABLE_DATA_REQUEST,
      });

      const { data } = await axios.post(`${BASE_URL}`, {
        service_name,
        page,
        token,
        limit,
      });
      dispatch({
        type: GET_SECONDARY_PICKLIST_TABLE_DATA_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: GET_SECONDARY_PICKLIST_TABLE_DATA_FAILURE,
        payload: error.message,
      });
    }
  };

export const getLostSubLocationActions =
  (service_name, page, token) => async (dispatch) => {
    try {
      dispatch({
        type: GET_LOST_SUB_LOCATION_LIST_TABLE_DATA_REQUEST,
      });

      const { data } = await axios.post(`${BASE_URL}`, {
        service_name,
        page,
        token,
      });
      dispatch({
        type: GET_LOST_SUB_LOCATION_LIST_TABLE_DATA_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: GET_LOST_SUB_LOCATION_LIST_TABLE_DATA_FAILURE,
        payload: error.message,
      });
    }
  };

export const getPickerActivityActions =
  (service_name, page, token) => async (dispatch) => {
    try {
      dispatch({
        type: GET_PICKER_ACTIVITY_TABLE_DATA_REQUEST,
      });

      const { data } = await axios.post(`${BASE_URL}`, {
        service_name,
        page,
        token,
      });
      dispatch({
        type: GET_PICKER_ACTIVITY_TABLE_DATA_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: GET_PICKER_ACTIVITY_TABLE_DATA_FAILURE,
        payload: error.message,
      });
    }
  };

export const getOrderListTableActions =
  (service_name, page, token, limit) => async (dispatch) => {
    try {
      dispatch({
        type: GET_ORDER_LIST_TABLE_DATA_REQUEST,
      });

      const { data } = await axios.post(`${BASE_URL}`, {
        service_name,
        page,
        token,
        limit,
      });
      dispatch({
        type: GET_ORDER_LIST_TABLE_DATA_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: GET_ORDER_LIST_TABLE_DATA_FAILURE,
        payload: error.message,
      });
    }
  };

export const getPortalWiseActions =
  (service_name, page, token, limit, filters) => async (dispatch) => {
    try {
      dispatch({
        type: GET_PORTAL_WISE_TABLE_DATA_REQUEST,
      });

      const { data } = await axios.post(`${BASE_URL}`, {
        service_name,
        page,
        token,
        limit,
        filters,
      });
      dispatch({
        type: GET_PORTAL_WISE_TABLE_DATA_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: GET_PORTAL_WISE_TABLE_DATA_FAILURE,
        payload: error.message,
      });
    }
  };

export const getCourierWiseActions =
  (service_name, page, token, limit, filters) => async (dispatch) => {
    try {
      dispatch({
        type: GET_COURIER_WISE_TABLE_DATA_REQUEST,
      });

      const { data } = await axios.post(`${BASE_URL}`, {
        service_name,
        page,
        token,
        limit,
        filters,
      });
      dispatch({
        type: GET_COURIER_WISE_TABLE_DATA_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: GET_COURIER_WISE_TABLE_DATA_FAILURE,
        payload: error.message,
      });
    }
  };

export const getDashboardUserActions =
  (service_name, page, token, limit, filters) => async (dispatch) => {
    try {
      dispatch({
        type: GET_DASHBOARD_USERS_REQUEST,
      });

      const { data } = await axios.post(`${BASE_URL}`, {
        service_name,
        page,
        token,
        limit,
        filters,
      });
      dispatch({
        type: GET_DASHBOARD_USERS_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: GET_DASHBOARD_USERS_FAILURE,
        payload: error.message,
      });
    }
  };

export const getToteListActions =
  (service_name, token, action, code) => async (dispatch) => {
    try {
      dispatch({
        type: GET_TOTE_LIST_REQUEST,
      });

      const { data } = await axios.post(`${BASE_URL}`, {
        service_name,
        token,
        action,
        code,
      });
      dispatch({
        type: GET_TOTE_LIST_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: GET_TOTE_LIST_FAILURE,
        payload: error.message,
      });
    }
  };

export const getPickerViewDetailsActions =
  (service_name, token, user_id, filters) => async (dispatch) => {
    try {
      dispatch({
        type: GET_PICKER_VIEW_DETAILS_REQUEST,
      });

      const { data } = await axios.post(`${BASE_URL}`, {
        service_name,
        token,
        user_id,
        filters,
      });
      dispatch({
        type: GET_PICKER_VIEW_DETAILS_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: GET_PICKER_VIEW_DETAILS_FAILURE,
        payload: error.message,
      });
    }
  };

export const addToteActions =
  (service_name, token, action, code) => async (dispatch) => {
    try {
      dispatch({
        type: ADD_TOTE_REQUEST,
      });

      const { data } = await axios.post(`${BASE_URL}`, {
        service_name,
        token,
        action,
        code,
      });
      dispatch({
        type: ADD_TOTE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: ADD_TOTE_FAILURE,
        payload: error.message,
      });
    }
  };

export const deleteToteActions =
  (service_name, token, action, tote_id) => async (dispatch) => {
    try {
      dispatch({
        type: DELETE_TOTE_REQUEST,
      });

      const { data } = await axios.post(`${BASE_URL}`, {
        service_name,
        token,
        action,
        tote_id,
      });
      dispatch({
        type: DELETE_TOTE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: DELETE_TOTE_FAILURE,
        payload: error.message,
      });
    }
  };

export const createPicklistRequirementActions =
  (service_name, token, order_ids) => async (dispatch) => {
    try {
      dispatch({
        type: CREATE_PICKLIST_REQUIREMENT_REQUEST,
      });

      const { data } = await axios.post(`${BASE_URL}`, {
        service_name,
        token,
        order_ids,
      });
      dispatch({
        type: CREATE_PICKLIST_REQUIREMENT_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: CREATE_PICKLIST_REQUIREMENT_FAILURE,
        payload: error.message,
      });
    }
  };

export const getAllAssigneeForPicklistActions =
  (service_name, token, limit, page) => async (dispatch) => {
    try {
      dispatch({
        type: GET_ALL_ASSIGNEE_FOR_PICKLIST_REQUEST,
      });

      const { data } = await axios.post(`${BASE_URL}`, {
        service_name,
        token,
        limit,
        page,
      });
      dispatch({
        type: GET_ALL_ASSIGNEE_FOR_PICKLIST_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: GET_ALL_ASSIGNEE_FOR_PICKLIST_FAILURE,
        payload: error.message,
      });
    }
  };

export const getAllCommunicationAlertActions =
  (service_name, token, limit, page) => async (dispatch) => {
    try {
      dispatch({
        type: GET_ALL_COMMUNICATION_ALERT_LIST_REQUEST,
      });

      const { data } = await axios.post(`${BASE_URL}`, {
        service_name,
        token,
        limit,
        page,
      });
      dispatch({
        type: GET_ALL_COMMUNICATION_ALERT_LIST_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: GET_ALL_COMMUNICATION_ALERT_LIST_FAILURE,
        payload: error.message,
      });
    }
  };

export const getAllCommunicationAlertCountActions =
  (service_name, token) => async (dispatch) => {
    try {
      dispatch({
        type: GET_ALL_COMMUNICATION_ALERT_LIST_COUNT_REQUEST,
      });

      const { data } = await axios.post(`${BASE_URL}`, {
        service_name,
        token,
      });
      dispatch({
        type: GET_ALL_COMMUNICATION_ALERT_LIST_COUNT_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: GET_ALL_COMMUNICATION_ALERT_LIST_COUNT_FAILURE,
        payload: error.message,
      });
    }
  };

export const AssignPicklisttoUsersActions =
  (service_name, token, picklist_id, user_id) => async (dispatch) => {
    try {
      dispatch({
        type: ASSIGN_PICKLIST_TO_USERS_REQUEST,
      });

      const { data } = await axios.post(`${BASE_URL}`, {
        service_name,
        token,
        picklist_id,
        user_id,
      });
      dispatch({
        type: ASSIGN_PICKLIST_TO_USERS_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: ASSIGN_PICKLIST_TO_USERS_FAILURE,
        payload: error.message,
      });
    }
  };

export const ReAssignPicklisttoUsersActions =
  (service_name, token, picklist_id, user_id) => async (dispatch) => {
    try {
      dispatch({
        type: REASSIGN_PICKLIST_TO_USERS_REQUEST,
      });

      const { data } = await axios.post(`${BASE_URL}`, {
        service_name,
        token,
        picklist_id,
        user_id,
      });
      dispatch({
        type: REASSIGN_PICKLIST_TO_USERS_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: REASSIGN_PICKLIST_TO_USERS_FAILURE,
        payload: error.message,
      });
    }
  };

export const CancelPicklistAction =
  (service_name, token, id) => async (dispatch) => {
    try {
      dispatch({
        type: CANCEL_PICKLIST_REQUEST,
      });

      const { data } = await axios.post(`${BASE_URL}`, {
        service_name,
        token,
        id,
      });
      dispatch({
        type: CANCEL_PICKLIST_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: CANCEL_PICKLIST_FAILURE,
        payload: error.message,
      });
    }
  };

export const getDashboardAllDataActions =
  (service_name, token, filters) => async (dispatch) => {
    try {
      dispatch({
        type: GET_DASHBOARD_ALL_DATA_REQUEST,
      });

      const { data } = await axios.post(`${BASE_URL}`, {
        service_name,
        token,
        filters,
      });
      dispatch({
        type: GET_DASHBOARD_ALL_DATA_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: GET_DASHBOARD_ALL_DATA_FAILURE,
        payload: error.message,
      });
    }
  };

export const getPicklistViewDetailsActions =
  (service_name, token, id) => async (dispatch) => {
    try {
      dispatch({
        type: GET_PICKLIST_VIEW_DETAILS_REQUEST,
      });

      const { data } = await axios.post(`${BASE_URL}`, {
        service_name,
        token,
        id,
      });
      dispatch({
        type: GET_PICKLIST_VIEW_DETAILS_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: GET_PICKLIST_VIEW_DETAILS_FAILURE,
        payload: error.message,
      });
    }
  };

export const getAllFiltersPickingModuleActions =
  (service_name, token, filters) => async (dispatch) => {
    try {
      dispatch({
        type: GET_ALL_FILTERS_PICKING_MODULE_REQUEST,
      });

      const { data } = await axios.post(`${BASE_URL}`, {
        service_name,
        token,
        filters,
      });
      dispatch({
        type: GET_ALL_FILTERS_PICKING_MODULE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: GET_ALL_FILTERS_PICKING_MODULE_FAILURE,
        payload: error.message,
      });
    }
  };

export const getSingleOrderLabelGeneratorActions =
  (service_name, token, tote_code) => async (dispatch) => {
    try {
      dispatch({
        type: GET_ALL_SINGLE_ORDER_LABEL_GENERATOR_REQUEST,
      });

      const { data } = await axios.post(`${BASE_URL}`, {
        service_name,
        token,
        tote_code,
      });
      dispatch({
        type: GET_ALL_SINGLE_ORDER_LABEL_GENERATOR_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: GET_ALL_SINGLE_ORDER_LABEL_GENERATOR_FAILURE,
        payload: error.message,
      });
    }
  };

export const getMultiOrderLabelGeneratorActions =
  (service_name, token, serial_code) => async (dispatch) => {
    try {
      dispatch({
        type: GET_ALL_MULTI_ORDER_LABEL_GENERATOR_REQUEST,
      });

      const { data } = await axios.post(`${BASE_URL}`, {
        service_name,
        token,
        serial_code,
      });
      dispatch({
        type: GET_ALL_MULTI_ORDER_LABEL_GENERATOR_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: GET_ALL_MULTI_ORDER_LABEL_GENERATOR_FAILURE,
        payload: error.message,
      });
    }
  };

export const AssignSecondaryOrderActions =
  (service_name, token, order_id, user_id) => async (dispatch) => {
    try {
      dispatch({
        type: ASSIGN_SECONDARY_ORDERS_PICKLIST_REQUEST,
      });

      const { data } = await axios.post(`${BASE_URL}`, {
        service_name,
        token,
        order_id,
        user_id,
      });
      dispatch({
        type: ASSIGN_SECONDARY_ORDERS_PICKLIST_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: ASSIGN_SECONDARY_ORDERS_PICKLIST_FAILURE,
        payload: error.message,
      });
    }
  };

export const ReAssignSecondaryOrderActions =
  (service_name, token, order_id, user_id) => async (dispatch) => {
    try {
      dispatch({
        type: REASSIGN_SECONDARY_ORDERS_PICKLIST_REQUEST,
      });

      const { data } = await axios.post(`${BASE_URL}`, {
        service_name,
        token,
        order_id,
        user_id,
      });
      dispatch({
        type: REASSIGN_SECONDARY_ORDERS_PICKLIST_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: REASSIGN_SECONDARY_ORDERS_PICKLIST_FAILURE,
        payload: error.message,
      });
    }
  };

export const VacantToteActions =
  (service_name, token, action, code) => async (dispatch) => {
    try {
      dispatch({
        type: VACANT_TOTE_REQUEST,
      });

      const { data } = await axios.post(`${BASE_URL}`, {
        service_name,
        token,
        action,
        code,
      });
      dispatch({
        type: VACANT_TOTE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: VACANT_TOTE_FAILURE,
        payload: error.message,
      });
    }
  };

export const ReadCommunicationCountActions =
  (service_name, token, id, type) => async (dispatch) => {
    try {
      dispatch({
        type: READ_COMMUNICATION_MODAL_REQUEST,
      });

      const { data } = await axios.post(`${BASE_URL}`, {
        service_name,
        token,
        id,
        type,
      });
      dispatch({
        type: READ_COMMUNICATION_MODAL_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: READ_COMMUNICATION_MODAL_FAILURE,
        payload: error.message,
      });
    }
  };

export const ChangePicklistStatusActions =
  (service_name, token, picklist_id, picklist_status) => async (dispatch) => {
    try {
      dispatch({
        type: CHANGE_PICKLIST_STATUS_REQUEST,
      });

      const { data } = await axios.post(`${BASE_URL}`, {
        service_name,
        token,
        picklist_id,
        picklist_status,
      });
      dispatch({
        type: CHANGE_PICKLIST_STATUS_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: CHANGE_PICKLIST_STATUS_FAILURE,
        payload: error.message,
      });
    }
  };

export const syncNotfoundMarkLostActions =
  (service_name, token, ids) => async (dispatch) => {
    try {
      dispatch({
        type: SYNC_NOT_FOUND_MARK_LOST_REQUEST,
      });

      const { data } = await axios.post(`${BASE_URL}`, {
        service_name,
        token,
        ids,
      });
      dispatch({
        type: SYNC_NOT_FOUND_MARK_LOST_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: SYNC_NOT_FOUND_MARK_LOST_FAILURE,
        payload: error.message,
      });
    }
  };

export const MarkedPlacedSingleLabelGeneratorActions =
  (service_name, token, tote_code) => async (dispatch) => {
    try {
      dispatch({
        type: MARKED_PLACED_SINGLE_LABEL_GENERATOR_REQUEST,
      });

      const { data } = await axios.post(`${BASE_URL}`, {
        service_name,
        token,
        tote_code,
      });
      dispatch({
        type: MARKED_PLACED_SINGLE_LABEL_GENERATOR_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: MARKED_PLACED_SINGLE_LABEL_GENERATOR_FAILURE,
        payload: error.message,
      });
    }
  };

export const MarkedPlacedMultiLabelGeneratorActions =
  (service_name, token, serial_code) => async (dispatch) => {
    try {
      dispatch({
        type: MARKED_PLACED_MULTI_LABEL_GENERATOR_REQUEST,
      });

      const { data } = await axios.post(`${BASE_URL}`, {
        service_name,
        token,
        serial_code,
      });
      dispatch({
        type: MARKED_PLACED_MULTI_LABEL_GENERATOR_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: MARKED_PLACED_MULTI_LABEL_GENERATOR_FAILURE,
        payload: error.message,
      });
    }
  };

export const getAllPredefinedProblemActions =
  (service_name, token, action, page) => async (dispatch) => {
    try {
      dispatch({
        type: GET_ALL_PREDEFINED_PROBLEMS_REQUEST,
      });

      const { data } = await axios.post(`${BASE_URL}`, {
        service_name,
        token,
        action,
        page,
      });
      dispatch({
        type: GET_ALL_PREDEFINED_PROBLEMS_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: GET_ALL_PREDEFINED_PROBLEMS_FAILURE,
        payload: error.message,
      });
    }
  };

export const AddPredefinedProblemActions =
  (service_name, token, action, text) => async (dispatch) => {
    try {
      dispatch({
        type: ADD_PREDEFINED_PROBLEMS_REQUEST,
      });

      const { data } = await axios.post(`${BASE_URL}`, {
        service_name,
        token,
        action,
        text,
      });
      dispatch({
        type: ADD_PREDEFINED_PROBLEMS_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: ADD_PREDEFINED_PROBLEMS_FAILURE,
        payload: error.message,
      });
    }
  };

export const DeletePredefinedProblemActions =
  (service_name, token, action, id) => async (dispatch) => {
    try {
      dispatch({
        type: DELETE_PREDEFINED_PROBLEMS_REQUEST,
      });

      const { data } = await axios.post(`${BASE_URL}`, {
        service_name,
        token,
        action,
        id,
      });
      dispatch({
        type: DELETE_PREDEFINED_PROBLEMS_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: DELETE_PREDEFINED_PROBLEMS_FAILURE,
        payload: error.message,
      });
    }
  };

export const EditPredefinedProblemActions =
  (service_name, token, action, text, id) => async (dispatch) => {
    try {
      dispatch({
        type: EDIT_PREDEFINED_PROBLEMS_REQUEST,
      });

      const { data } = await axios.post(`${BASE_URL}`, {
        service_name,
        token,
        action,
        text,
        id,
      });
      dispatch({
        type: EDIT_PREDEFINED_PROBLEMS_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: EDIT_PREDEFINED_PROBLEMS_FAILURE,
        payload: error.message,
      });
    }
  };

export const picklistAnotherDetailsActions =
  (service_name, token, picklist_id) => async (dispatch) => {
    try {
      dispatch({
        type: PICKLIST_ANOTHER_DEPTH_DETAILS_REQUEST,
      });

      const { data } = await axios.post(`${BASE_URL}`, {
        service_name,
        token,
        picklist_id,
      });
      dispatch({
        type: PICKLIST_ANOTHER_DEPTH_DETAILS_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: PICKLIST_ANOTHER_DEPTH_DETAILS_FAILURE,
        payload: error.message,
      });
    }
  };

export const SecondaryPicklistDetailsActions =
  (service_name, token, order_id) => async (dispatch) => {
    try {
      dispatch({
        type: SECONDARY_ORDER_DETAILS_REQUEST,
      });

      const { data } = await axios.post(`${BASE_URL}`, {
        service_name,
        token,
        order_id,
      });
      dispatch({
        type: SECONDARY_ORDER_DETAILS_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: SECONDARY_ORDER_DETAILS_FAILURE,
        payload: error.message,
      });
    }
  };

export const listCreateOrderDetailsActions =
  (service_name, token, order_id) => async (dispatch) => {
    try {
      dispatch({
        type: LIST_CREATE_ORDER_DETAILS_REQUEST,
      });

      const { data } = await axios.post(`${BASE_URL}`, {
        service_name,
        token,
        order_id,
      });
      dispatch({
        type: LIST_CREATE_ORDER_DETAILS_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: LIST_CREATE_ORDER_DETAILS_FAILURE,
        payload: error.message,
      });
    }
  };

export const add_permissionActions =
  (service_name, token) => async (dispatch) => {
    try {
      dispatch({
        type: ADD_PERMISSIONS_REQUEST,
      });

      const { data } = await axios.post(`${BASE_URL}`, {
        service_name,
        token,
      });
      dispatch({
        type: ADD_PERMISSIONS_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: ADD_PERMISSIONS_FAILURE,
        payload: error.message,
      });
    }
  };

export const add_bypassActions =
  (service_name, token, code) => async (dispatch) => {
    try {
      dispatch({
        type: ADD_BYPASS_REQUEST,
      });

      const { data } = await axios.post(`${BASE_URL}`, {
        service_name,
        token,
        code,
      });
      dispatch({
        type: ADD_BYPASS_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: ADD_BYPASS_FAILURE,
        payload: error.message,
      });
    }
  };

export const remove_bypassActions =
  (service_name, token, code) => async (dispatch) => {
    try {
      dispatch({
        type: REMOVE_BYPASS_REQUEST,
      });

      const { data } = await axios.post(`${BASE_URL}`, {
        service_name,
        token,
        code,
      });
      dispatch({
        type: REMOVE_BYPASS_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: REMOVE_BYPASS_FAILURE,
        payload: error.message,
      });
    }
  };

export const MarkLostDataActions =
  (service_name, token) => async (dispatch) => {
    try {
      dispatch({
        type: MARK_LOST_TABLE_DATA_REQUEST,
      });

      const { data } = await axios.post(`${BASE_URL}`, {
        service_name,
        token,
      });
      dispatch({
        type: MARK_LOST_TABLE_DATA_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: MARK_LOST_TABLE_DATA_FAILURE,
        payload: error.message,
      });
    }
  };

export const GetRequestCountActions =
  (service_name, token, product_id, sublocation_id, inventory_pick_list_id) =>
  async (dispatch) => {
    try {
      dispatch({
        type: GET_MARK_LOST_COUNT_REQUEST,
      });

      const { data } = await axios.post(`${BASE_URL}`, {
        service_name,
        token,
        product_id,
        sublocation_id,
        inventory_pick_list_id,
      });
      dispatch({
        type: GET_MARK_LOST_COUNT_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: GET_MARK_LOST_COUNT_FAILURE,
        payload: error.message,
      });
    }
  };

export const ApproveRejectMarkLostWithoutAuditActions =
  (service_name, token, product_id, sub_location_id, action) =>
  async (dispatch) => {
    try {
      dispatch({
        type: APPROVE_REJECT_MARK_LOST_REQUEST,
      });

      const { data } = await axios.post(`${BASE_URL}`, {
        service_name,
        token,
        product_id,
        sub_location_id,
        action,
      });
      dispatch({
        type: APPROVE_REJECT_MARK_LOST_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: APPROVE_REJECT_MARK_LOST_FAILURE,
        payload: error.message,
      });
    }
  };

export const StartAuditRequestActions =
  (service_name, token, product_id, sublocation_id) => async (dispatch) => {
    try {
      dispatch({
        type: START_AUDIT_REQUEST,
      });

      const { data } = await axios.post(`${BASE_URL}`, {
        service_name,
        token,
        product_id,
        sublocation_id,
      });
      dispatch({
        type: START_AUDIT_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: START_AUDIT_FAILURE,
        payload: error.message,
      });
    }
  };

export const ScanAuditRequestActions =
  (service_name, token, audit_id, sublocation_code, bbsc_code) =>
  async (dispatch) => {
    try {
      dispatch({
        type: SCAN_AUDIT_REQUEST,
      });

      const { data } = await axios.post(`${BASE_URL}`, {
        service_name,
        token,
        audit_id,
        sublocation_code,
        bbsc_code,
      });
      dispatch({
        type: SCAN_AUDIT_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: SCAN_AUDIT_FAILURE,
        payload: error.message,
      });
    }
  };

export const CompleteAuditRequestActions =
  (service_name, token, audit_id) => async (dispatch) => {
    try {
      dispatch({
        type: COMPLETE_AUDIT_REQUEST,
      });

      const { data } = await axios.post(`${BASE_URL}`, {
        service_name,
        token,
        audit_id,
      });
      dispatch({
        type: COMPLETE_AUDIT_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: COMPLETE_AUDIT_FAILURE,
        payload: error.message,
      });
    }
  };

export const SettingsRequestActions =
  (service_name, token, settings) => async (dispatch) => {
    try {
      dispatch({
        type: SETTINGS_REQUEST,
      });

      const { data } = await axios.post(`${BASE_URL}`, {
        service_name,
        token,
        settings,
      });
      dispatch({
        type: SETTINGS_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: SETTINGS_FAILURE,
        payload: error.message,
      });
    }
  };

export const getSettingsRequestActions =
  (service_name, token) => async (dispatch) => {
    try {
      dispatch({
        type: GET_SETTINGS_REQUEST,
      });

      const { data } = await axios.post(`${BASE_URL}`, {
        service_name,
        token,
      });
      dispatch({
        type: GET_SETTINGS_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: GET_SETTINGS_FAILURE,
        payload: error.message,
      });
    }
  };

export const syncMarkLostRequestActions =
  (service_name, token) => async (dispatch) => {
    try {
      dispatch({
        type: MARK_LOST_SYNC_REQUEST,
      });

      const { data } = await axios.post(`${BASE_URL}`, {
        service_name,
        token,
      });
      dispatch({
        type: MARK_LOST_SYNC_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: MARK_LOST_SYNC_FAILURE,
        payload: error.message,
      });
    }
  };

export const byPassAllSecondaryOrderActions =
  (service_name, token, codes) => async (dispatch) => {
    try {
      dispatch({
        type: BYPASS_ALL_ORDER_REQUEST,
      });

      const { data } = await axios.post(`${BASE_URL}`, {
        service_name,
        token,
        codes,
      });
      dispatch({
        type: BYPASS_ALL_ORDER_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: BYPASS_ALL_ORDER_FAILURE,
        payload: error.message,
      });
    }
  };

export const DeviceManagementActions =
  (service_name, token, page, limit) => async (dispatch) => {
    try {
      dispatch({
        type: GET_DEVICE_MANAGEMENT_DATA_REQUEST,
      });

      const { data } = await axios.post(`${BASE_URL}`, {
        service_name,
        token,
        page,
        limit,
      });
      dispatch({
        type: GET_DEVICE_MANAGEMENT_DATA_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: GET_DEVICE_MANAGEMENT_DATA_FAILURE,
        payload: error.message,
      });
    }
  };

export const GetPickingCountAfterLostRequestActions =
  (service_name, token, request_id) => async (dispatch) => {
    try {
      dispatch({
        type: GET_PICKING_COUNT_AFTER_LOST__REQUEST,
      });

      const { data } = await axios.post(`${BASE_URL}`, {
        service_name,
        token,
        request_id,
      });
      dispatch({
        type: GET_PICKING_COUNT_AFTER_LOST__SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: GET_PICKING_COUNT_AFTER_LOST__FAILURE,
        payload: error.message,
      });
    }
  };

export const PrintNotFoundOrderLabelActions =
  (service_name, token, order_ids) => async (dispatch) => {
    try {
      dispatch({
        type: PRINT_NOT__FOUND__ORDER__LABEL__REQUEST,
      });

      const { data } = await axios.post(`${BASE_URL}`, {
        service_name,
        token,
        order_ids,
      });
      dispatch({
        type: PRINT_NOT__FOUND__ORDER__LABEL__SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: PRINT_NOT__FOUND__ORDER__LABEL__FAILURE,
        payload: error.message,
      });
    }
  };

// clearing errors
export const clearErrors = () => async (dispatch) => {
  dispatch({
    type: CLEAR_ERRORS,
  });
};
