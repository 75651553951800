import React from "react";
import "./Loader.css";

const Loader = (props) => {
  return (
    <div
      className="loader"
      style={{
        width: props.isSmall && "12px",
        height: props.isSmall && "12px",
        border: props.invoice && "3px solid #46cc8d",
        borderTop: props.invoice && "3px solid black",
      }}
    ></div>
  );
};

export default Loader;
