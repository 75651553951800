import { combineReducers } from "redux";
import {
  CloseHandoverBagReducers,
  CloseHandoverOrderReducers,
  CloseReverseHandoverByIdReducers,
  SaveReversalHandoverReducers,
  ScanorderWithAwbNoReducers,
  createHandoverBagReducers,
  editHandoverItemReducers,
  editReverseHandoverReducers,
  getAllBagReducers,
  getAllHandoverNameReducers,
  getAllHandoverNameReverseOrderReducers,
  getBagManifestReducers,
  getCarrierReducers,
  getCourierNameByIdReducers,
  getCurrentBagQuantityReducers,
  getInventoryListReducers,
  getInvoiceListForMatchReducers,
  getOrderHandoverAwbReducers,
  getOrderHandoverSummaryReducers,
  getReverseOrderHandoverReducers,
  getReverse_inventory_invoiceListReducers,
  getScanItemListReducers,
  getScannedItemReverseOrderReducers,
  getTotalBagScanQuantityReducers,
  getUniqueHandoverName,
  getUniqueReverseHandoverReducers,
  getUserListReducers,
  printlabelbagReducers,
  reverseHandoverByIdReducers,
  saveDeliveryPartnerReducers,
} from "./Reducers/OrderHandoverReducers/OrderHandoverReducers";
import {
  AddPredefinedProblemsReducers,
  AddToteReducers,
  ApproveRejectMarkLostRequestReducers,
  AssignPicklistUsersReducers,
  AssignSecondaryOrderPicklistReducers,
  ByPassAllSecondaryOrderReducers,
  ChangePicklistStatusReducers,
  CompleteAuditRequestReducers,
  DeletePredefinedProblemsReducers,
  DeviceManagementReducers,
  EditPredefinedProblemsReducers,
  GetAllPredefinedProblemsReducers,
  ListOrderDetailsReducers,
  MarkLostCountRequestReducers,
  MarkLostTableArrReducers,
  MarkedPlacedMultiLabelGeneratorReducers,
  MarkedPlacedSingleLabelGeneratorReducers,
  PicklistAnotherDetailsReducers,
  PrintNotFoundOrderLabelReducers,
  ReAssignPicklistUsersReducers,
  ReAssignSecondaryOrderPicklistReducers,
  ReadCommunicationCountReducers,
  ScanAuditRequestReducers,
  SecondaryPicklistDetailsReducers,
  SettingsReducers,
  StartAuditRequestReducers,
  VacantToteReducers,
  add_bypassReducers,
  add_permissionReducers,
  cancelPicklistReducers,
  createPicklistRequirementReducers,
  deleteToteReducers,
  getAllAssigneeforPicklistReducers,
  getAllCommunicationAlertCountReducers,
  getAllCommunicationAlertReducers,
  getAllFiltersPickingReducers,
  getAllMultiOrderLabelGeneratorReducers,
  getAllSingleOrderLabelGeneratorReducers,
  getCourierWiseDataReducers,
  getDashboardAllDataReducers,
  getDashboardUserReducers,
  getLostSubLocationReducers,
  getOrderListTableReducers,
  getPickerActivityReducers,
  getPickerManagementReducers,
  getPickerViewDetails,
  getPickingAfterLostRequestReducers,
  getPicklistManagementReducers,
  getPicklistViewDetailReducers,
  getPortalWiseDataReducers,
  getSecondaryPicklistManagementReducers,
  getSettingsReducers,
  getToteListReducers,
  getWithWoutDataReducers,
  remove_bypassReducers,
  syncMarkLostRequestReducers,
  syncNotfoundMarkLostReducers,
} from "./Reducers/PickerMgmt/PickerMgmtReducers";

const rootReducer = combineReducers({
  pickerManagement: getPickerManagementReducers,
  picklistManagement: getPicklistManagementReducers,
  secondaryPicklistManagement: getSecondaryPicklistManagementReducers,
  lostSublocation: getLostSubLocationReducers,
  pickerActivity: getPickerActivityReducers,
  carriers: getCarrierReducers,
  uniqueHandoverName: getUniqueHandoverName,
  saveDelivery: saveDeliveryPartnerReducers,
  orderList: getOrderListTableReducers,
  orderSummary: getOrderHandoverSummaryReducers,
  portalwise: getPortalWiseDataReducers,
  orderwise: getCourierWiseDataReducers,
  toteList: getToteListReducers,
  courierById: getCourierNameByIdReducers,
  getScanItemList: getScanItemListReducers,
  createHandover: createHandoverBagReducers,
  bagQty: getCurrentBagQuantityReducers,
  pickerDetails: getPickerViewDetails,
  addTote: AddToteReducers,
  deleteTote: deleteToteReducers,
  allBagsName: getAllBagReducers,
  totalBagsScan: getTotalBagScanQuantityReducers,
  orderAwb: getOrderHandoverAwbReducers,
  createPicklist: createPicklistRequirementReducers,
  assignee: getAllAssigneeforPicklistReducers,
  communication: getAllCommunicationAlertReducers,
  communicationCount: getAllCommunicationAlertCountReducers,
  assignPicklist: AssignPicklistUsersReducers,
  reassignPicklist: ReAssignPicklistUsersReducers,
  userList: getUserListReducers,
  cancelPicklist: cancelPicklistReducers,
  dashboardAllData: getDashboardAllDataReducers,
  picklistViewDetails: getPicklistViewDetailReducers,
  reverseHandover: reverseHandoverByIdReducers,
  editHandover: editHandoverItemReducers,
  reverseHandoverSummary: getReverseOrderHandoverReducers,
  uniquehandoverReversalName: getUniqueReverseHandoverReducers,
  saveReversal: SaveReversalHandoverReducers,
  scanReversalOrder: ScanorderWithAwbNoReducers,
  handoverAllName: getAllHandoverNameReducers,
  closeBag: CloseHandoverBagReducers,
  reverseOrderHandoverAllName: getAllHandoverNameReverseOrderReducers,
  scanItemReverseOrderList: getScannedItemReverseOrderReducers,
  editReverseHandover: editReverseHandoverReducers,
  allPickingModuleFilters: getAllFiltersPickingReducers,
  singleLabel: getAllSingleOrderLabelGeneratorReducers,
  multiLabel: getAllMultiOrderLabelGeneratorReducers,
  closeHandoverOrder: CloseHandoverOrderReducers,
  // downloadCSVByid: DownloadHandoverCSVByIdReducers,
  assignSecondaryOrders: AssignSecondaryOrderPicklistReducers,
  reAssignSecondaryOrders: ReAssignSecondaryOrderPicklistReducers,
  dashboardUsers: getDashboardUserReducers,
  vacantTote: VacantToteReducers,
  readCommunication: ReadCommunicationCountReducers,
  closeReversal_order: CloseReverseHandoverByIdReducers,
  picklist_status: ChangePicklistStatusReducers,
  mark_lost_not_found: syncNotfoundMarkLostReducers,
  markedMultiLabel: MarkedPlacedMultiLabelGeneratorReducers,
  markedSingleLabel: MarkedPlacedSingleLabelGeneratorReducers,
  predefinedProblems: GetAllPredefinedProblemsReducers,
  addProblem: AddPredefinedProblemsReducers,
  deleteProblem: DeletePredefinedProblemsReducers,
  bagManifestPdf: getBagManifestReducers,
  listofInventory: getInventoryListReducers,
  labelBag: printlabelbagReducers,
  editProblem: EditPredefinedProblemsReducers,
  picklistAnotherDetails: PicklistAnotherDetailsReducers,
  secondary_details: SecondaryPicklistDetailsReducers,
  listCreateOrderDetails: ListOrderDetailsReducers,
  invoice_data_list: getInvoiceListForMatchReducers,
  permissions_data: add_permissionReducers,
  bypassadd: add_bypassReducers,
  bypassremove: remove_bypassReducers,
  reverse_inventories_list: getReverse_inventory_invoiceListReducers,
  MarkLost: MarkLostTableArrReducers,
  RequestCount: MarkLostCountRequestReducers,
  getAudit: getWithWoutDataReducers,
  ApproveRejectMarkLostWithoutAudit: ApproveRejectMarkLostRequestReducers,
  StartAudit: StartAuditRequestReducers,
  ScanAudit: ScanAuditRequestReducers,
  CompleteAudit: CompleteAuditRequestReducers,
  SettingsRequest: SettingsReducers,
  settingsGet: getSettingsReducers,
  syncMarkLost: syncMarkLostRequestReducers,
  byPassAllOrder: ByPassAllSecondaryOrderReducers,
  DeviceList: DeviceManagementReducers,
  PickingCountAfterLostRequest: getPickingAfterLostRequestReducers,
  PrintNotFoundOrderLabel: PrintNotFoundOrderLabelReducers,
});

export default rootReducer;

////////////
