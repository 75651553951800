import React, { Fragment, useEffect } from "react";
import { HeadingTitle } from "../../Modal/Modal";
import LoaderAlone from "../../Loader/LoaderAlone";
import DataTable from "../../Table/DataTable";
import { RequestPickingCountHeader } from "../../../DummyArray/TableArray/TableData";
import { useDispatch, useSelector } from "react-redux";
import { GetPickingCountAfterLostRequestActions } from "../../../Actions/PickerMgmt/PickerMgmtActions";

const PickingAfterLostRequest = ({ close, request_id }) => {
  let isAuthenticated = JSON.parse(localStorage.getItem("user"));
  let token = isAuthenticated && isAuthenticated?.token;

  const dispatch = useDispatch();

  //   Picking Count State
  const { picking_after_lostRequest, loading: picking_count_loading } =
    useSelector((state) => state.PickingCountAfterLostRequest);

  useEffect(() => {
    if (request_id) {
      dispatch(
        GetPickingCountAfterLostRequestActions(
          "get_picking_after_lost_request",
          token,
          request_id
        )
      );
    }
  }, [token, request_id, dispatch]);

  console.log("PICKING COUNT AFTER LOST REQUEST: ", picking_after_lostRequest);

  return (
    <Fragment>
      <HeadingTitle
        title={`Picking Count After Lost Request`}
        closeModal={close}
      />

      {/* Table Component starts here */}
      <div className="table-wrapper-pagination">
        <div className="table-container-pagination">
          {picking_count_loading ? (
            <LoaderAlone />
          ) : (
            <DataTable
              header={RequestPickingCountHeader}
              pickingCountAfterLostRequest={
                picking_after_lostRequest && picking_after_lostRequest?.data
              }
            />
          )}
        </div>
      </div>
      {/* Table Component ends here */}
    </Fragment>
  );
};

export default PickingAfterLostRequest;
