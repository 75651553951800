import React from "react";
import Headline from "../../Components/HeadlineSpecial/Headline";
import LoaderAlone from "../../Components/Loader/LoaderAlone";
import Header from "../../Components/Header/Header";
import SideBar from "../../Components/SideBar/SideBar";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import {
  createPicklistRequirementActions,
  getAllFiltersPickingModuleActions,
  getOrderListTableActions,
  PrintNotFoundOrderLabelActions,
} from "../../Actions/PickerMgmt/PickerMgmtActions";
import Dropdown from "../../Components/Dropdown/Dropdown";
import DataTable from "../../Components/Table/DataTable";
import {
  mark_lost_not_found,
  orderTable,
  picklist_order_type_list,
} from "../../DummyArray/TableArray/TableData";
import { useState } from "react";
import { useCallback } from "react";
import { Pagination } from "../../Components/Pagination/Pagination";
import axios from "axios";
import { BASE_URL } from "../../Base_URL/BaseURL";
import NoDataFromApi from "../../Components/Loader/NoDataFromApi";
import "./CreateOrder.css";
import { Button } from "@material-ui/core";
import { useAlert } from "react-alert";
import { CREATE_PICKLIST_REQUIREMENT_RESET } from "../../Constants/PickerMgmt/PickerManagementConstants";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { useMemo } from "react";
import ModalContainer from "../../Components/Modal/Modal";
import MetaData from "../../Components/Helmet/Helmet";
import Loader from "../../Components/Loader/Loader";
import qz from "qz-tray";

const CreateOrder = () => {
  const alert = useAlert();
  let isAuthenticated = JSON.parse(localStorage.getItem("user"));
  let token = isAuthenticated && isAuthenticated?.token;
  const dispatch = useDispatch();
  const { list_orders, status, loading } = useSelector(
    (state) => state.orderList
  );

  const [limit, setLimit] = useState(10);
  const [changeLimit, setChangeLimit] = useState(false);

  const handleLimit = (event) => {
    setChangeLimit(true);
    if (event.key === "Enter") {
      setPage(1);
      // dispatch(getOrderListTableActions("list_orders", 1, token, limit));
      fetchFilters();
    }
  };

  const handleLimitTag = (e) => {
    setChangeLimit(true);
    setLimit(e.target.value);
  };

  const {
    message,
    status: CreateStatus,
    isCreated,
    loading: create_loading,
  } = useSelector((state) => state.createPicklist);

  // pagination code starts here
  const [orderList, setOrder_List] = useState([]);
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);

  const [filtersTrue, setFilterTrue] = useState(false);
  const [pagination, setPagination] = useState(0);

  const fetch = useCallback(
    (page) => {
      setIsLoading(true);
      axios
        .post(`${BASE_URL}`, {
          service_name: "list_orders",
          page,
          token,
          limit,
        })
        .then((response) => {
          setOrder_List(response?.data?.data);
          setIsLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setIsLoading(false);
        });
    },
    [limit, token]
  );

  const handlePage = useCallback(
    (page, paginate) => {
      setOrderId([]);
      setPagination(page);
      setPage(page);
      if (!filtersTrue && pagination >= 0 && paginate) {
        fetch(page);
      }
    },
    [fetch, filtersTrue, pagination]
  );

  const fetchPicklist = useCallback(() => {
    list_orders && setOrder_List(list_orders && list_orders?.data);
  }, [list_orders]);

  useEffect(() => {
    fetchPicklist();
  }, [fetchPicklist]);

  // filters code starts here
  const [purchased_from, setPurchased_from] = useState(null);

  const [purchased_to, setPurchased_to] = useState(null);

  const [tracking_no, setTracking_no] = useState(null);

  const [portal_name, setPortal_name] = useState("");
  const [portal_id, setPortal_id] = useState("");

  const [order_no, setOrder_no] = useState("");
  const [is_picklist, setis_picklist] = useState("");
  const [is_picklist_id, setis_picklist_id] = useState("");

  const [picklist_order_type_name, setPicklist_order_type_name] = useState("");
  const [picklist_order_type, setPicklist_order_type] = useState("");

  const [filterTotal_pages, setFilter_total_pages] = useState(0);
  const [filter_Loading, setFilter_loading] = useState(false);

  const [picklist_id, setPicklist_id] = useState("");

  const [created_Date, setCreated_Date] = useState(null);
  const [to_created_Date, setTo_created_Date] = useState(null);

  const [is_handover_done, setIsHandover_done] = useState("");
  const [is_handover_done_name, setIsHandover_done_name] = useState("");

  const [is_valid_order, setIs_valid_order] = useState("");
  const [is_valid_order_name, setIsValid_order_name] = useState("");

  const [mapped_carrier_name, setmapped_carrier_name] = useState("");
  const [is_not_found, setis_not_found] = useState("");

  const handleisPicklist = (val) => {
    setis_picklist(val?.id);
    setFilterTrue(true);
    setis_picklist_id(val?.value);
    handlePage(1, false);
  };

  // New Filter
  const [is_label_generated, setis_label_generated] = useState("");
  const [is_label_generated_value, setIsLabel_generated_value] = useState("");

  const [is_bulk_label_generated, setis_bulk_label_generated] = useState("");
  const [is_bulk_label_generated_value, setis_bulk_label_generated_value] =
    useState("");

  const handleLabelGenerated = (val) => {
    setis_label_generated(val?.id);
    setIsLabel_generated_value(val?.value);
    setFilterTrue(true);
    handlePage(1, false);
  };

  const handleBulkLabelGenerated = (val) => {
    setis_bulk_label_generated(val?.id);
    setis_bulk_label_generated_value(val?.value);
    setFilterTrue(true);
    handlePage(1, false);
  };

  const handleIsNotFound = (val) => {
    setOrderId([]);
    setis_not_found(val?.id);
    setFilterTrue(true);
    handlePage(1, false);
  };

  const handleHandoverDone = (val) => {
    setIsHandover_done(val?.id);
    setIsHandover_done_name(val?.value);
    setFilterTrue(true);
    handlePage(1, false);
  };

  const handleValidOrder = (val) => {
    setIs_valid_order(val?.id);
    setIsValid_order_name(val?.value);
    setFilterTrue(true);
    handlePage(1, false);
  };

  const handleCourierName = (val) => {
    setmapped_carrier_name(val?.id);
    setFilterTrue(true);
    handlePage(1, false);
  };

  // New Filter

  const handleOrderNo = (e) => {
    setOrder_no(e.target.value);
    setFilterTrue(true);
    handlePage(1, false);
  };

  const handlePicklist_id = (e) => {
    setPicklist_id(e.target.value);
    setFilterTrue(true);
    handlePage(1, false);
  };

  const handleKeyOrderNo = (event) => {
    if (event.key === "Enter") {
      setFilterTrue(true);
      fetchFilters();
      handlePage(1, false);
    }
  };

  const handlePicklistId = (event) => {
    if (event.key === "Enter") {
      setFilterTrue(true);
      fetchFilters();
    }
  };

  const handleTrackingNo = (e) => {
    setFilterTrue(true);
    setTracking_no(e.target.value);
    handlePage(1, false);
  };

  const handleKeyTrackingNo = (event) => {
    if (event.key === "Enter") {
      setFilterTrue(true);
      fetchFilters();
      handlePage(1, false);
    }
  };

  const handlePortalName = (val) => {
    setFilterTrue(true);
    setPortal_name(val?.value);
    setPortal_id(val?.id);
    handlePage(1, false);
  };

  const handlePicklistOrderType = (val) => {
    setFilterTrue(true);
    setPicklist_order_type(val?.id);
    setPicklist_order_type_name(val?.value);
    handlePage(1, false);
  };

  const handlePurchasedOn = (date) => {
    setFilterTrue(true);
    setPurchased_from(date);
    handlePage(1, false);
  };

  const handleCreatedOn = (date) => {
    setFilterTrue(true);
    setPurchased_to(date);
    handlePage(1, false);
  };

  const handleCreatedOn_Date = (date) => {
    setFilterTrue(true);
    setCreated_Date(date);
    handlePage(1, false);
  };

  const handleCreatedOn_toDate = (date) => {
    setFilterTrue(true);
    setTo_created_Date(date);
    handlePage(1, false);
  };

  let from_purchase_date =
    purchased_from !== "" ? moment(purchased_from).format("yyyy-MM-DD") : null;
  let to_purchase_date =
    purchased_to !== "" ? moment(purchased_to).format("yyyy-MM-DD") : null;

  let from_created_on =
    created_Date !== "" ? moment(created_Date).format("yyyy-MM-DD") : null;
  let to_created_on =
    to_created_Date !== ""
      ? moment(to_created_Date).format("yyyy-MM-DD")
      : null;

  let filters = useMemo(() => {
    const dataToSend = {
      portal_id,
      picklist_order_type,
      from_purchase_date,
      order_no,
      tracking_no,
      to_purchase_date,
      is_picklist,
      picklist_id,
      from_created_on,
      to_created_on,
      is_label_generated,
      is_bulk_label_generated,
      is_handover_done,
      is_valid_order,
      mapped_carrier_name,
      is_not_found,
    };
    // Filtering empty value
    const filter_data = Object.fromEntries(
      Object.entries(dataToSend).filter(
        ([_, value]) =>
          value !== "" && value !== null && value !== "Invalid date"
      )
    );
    return filter_data;
  }, [
    portal_id,
    picklist_order_type,
    order_no,
    tracking_no,
    to_purchase_date,
    from_purchase_date,
    is_picklist,
    picklist_id,
    from_created_on,
    to_created_on,
    is_bulk_label_generated,
    is_label_generated,
    is_handover_done,
    is_valid_order,
    mapped_carrier_name,
    is_not_found,
  ]);

  const fetchFilters = useCallback(() => {
    setFilter_loading(true);
    axios
      .post(`${BASE_URL}`, {
        service_name: "list_orders",
        token,
        page,
        filters,
        limit,
      })
      .then((response) => {
        if (response && response?.data?.status === 0) {
          alert.error(response && response?.data?.msg);
        } else {
          setFilter_total_pages(response?.data?.total_pages);
          setOrder_List(response?.data?.data);
          setFilter_loading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setFilter_loading(false);
      });
  }, [token, page, filters, alert, limit]);

  useEffect(() => {
    if (filtersTrue === true) {
      fetchFilters();
    } else if (
      filtersTrue === false &&
      pagination === 0 &&
      changeLimit === false
    ) {
      dispatch(getOrderListTableActions("list_orders", 1, token, limit));
    }
  }, [
    dispatch,
    token,
    filtersTrue,
    fetchFilters,
    pagination,
    limit,
    changeLimit,
  ]);

  // filter code ends here

  const [isIndex, setIsindex] = useState([]);
  const [isOk, setIsOk] = useState(true);
  const [oldIndex, setOldIndex] = useState([]);
  let order_ids = [...new Set([...isIndex, ...oldIndex])];

  const handleIndex = (index) => {
    setOldIndex((prev) => [...prev, index]);
    if (isIndex.includes(index) || oldIndex.includes(index)) {
      setIsindex((prevNum) => prevNum.filter((num) => num !== index));
      setOldIndex((prevNum) => prevNum.filter((num) => num !== index));
    } else {
      setIsindex((prev) => [...prev, index]);
      setOldIndex((prev) => [...prev, index]);
    }
  };

  const handleIndexAll = () => {
    setIsOk(!isOk);
    if (isOk) {
      orderList &&
        orderList.forEach((i) => {
          if (i.is_pick_list === 0 && i.show_checkbox === 1) {
            if (isIndex.includes(i.id)) {
              setIsindex((prevNum) => prevNum.filter((num) => num !== i.id));
            } else {
              setIsindex((prev) => [...prev, i.id]);
            }
          }
        });
    } else {
      setIsindex([]);
      setOldIndex([]);
    }
  };

  const handleNull = () => {
    setIsindex([]);
    setOldIndex([]);
    setIsOk(!isOk);
  };

  let All_Filters = useMemo(() => {
    const dataToSend = ["portals", "mapped_carrier_names"];
    return dataToSend;
  }, []);

  const { all_filters, loading: allFilter_loading } = useSelector(
    (state) => state.allPickingModuleFilters
  );

  let portal_name_list = all_filters && all_filters?.portals;
  let carrier_name_list = all_filters && all_filters?.mapped_carrier_names;

  useEffect(() => {
    dispatch(getAllFiltersPickingModuleActions("filters", token, All_Filters));
  }, [dispatch, token, All_Filters]);

  const handlePicklistCreate = () => {
    if (order_ids && order_ids.length > 0) {
      dispatch(
        createPicklistRequirementActions(
          "create_picklist_requirement",
          token,
          order_ids
        )
      );
    } else {
      alert.error("Please Select Ids...");
    }
  };

  useEffect(() => {
    if (CreateStatus === 1 && isCreated) {
      alert.success("Picklist Created Successfully!");
      dispatch(getOrderListTableActions("list_orders", 1, token, limit));
      setPage(1);
      dispatch({
        type: CREATE_PICKLIST_REQUIREMENT_RESET,
      });
    } else if (CreateStatus === 0 && isCreated) {
      alert.error(message);
      dispatch({
        type: CREATE_PICKLIST_REQUIREMENT_RESET,
      });
    }
  }, [dispatch, token, CreateStatus, isCreated, alert, message, limit]);

  let ActiveOrders = [];
  orderList &&
    orderList.forEach((i) => {
      if (i.is_pick_list === 0 && !ActiveOrders.includes(i.id)) {
        ActiveOrders.push(i);
      } else {
        return null;
      }
    });

  const [isIndexs, setIsIndexs] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [isData, setIsData] = useState([]);

  const closeModal = () => {
    setIsIndexs(null);
    setIsOpen(false);
  };

  const renderModal = () => {
    if (isIndex !== null) {
      return (
        <ModalContainer
          Isopen={isOpen}
          index={isIndexs}
          closeModal={closeModal}
          isData={isData}
        />
      );
    }
    return null;
  };

  const handlePicklistDetails = (data, index) => {
    setIsIndexs(index);
    setIsData(data);
    setIsOpen(true);
  };

  const resteFilter = () => {
    setPortal_id("");
    setPortal_name("");
    setPicklist_order_type("");
    setPicklist_order_type_name("");
    setPurchased_from("");
    setOrder_no("");
    setTracking_no("");
    setPurchased_to("");
    setCreated_Date("");
    setTo_created_Date("");
    setis_picklist("");
    setPicklist_id("");
    setis_picklist_id("");
    setis_bulk_label_generated("");
    setis_bulk_label_generated_value("");
    setis_label_generated("");
    setIsLabel_generated_value("");
    setIsHandover_done("");
    setIsHandover_done_name("");
    setIs_valid_order("");
    setIsValid_order_name("");
    setmapped_carrier_name("");
    setis_not_found("");
    setPage(1);
  };

  // useEffect(() => {
  //   if (
  //     portal_id ||
  //     portal_name ||
  //     picklist_order_type ||
  //     picklist_order_type_name ||
  //     purchased_from ||
  //     purchased_to ||
  //     order_no ||
  //     tracking_no ||
  //     created_Date ||
  //     to_created_Date ||
  //     is_picklist ||
  //     is_picklist_id
  //   ) {
  //     handlePage(1);
  //   }
  // }, [
  //   portal_id,
  //   portal_name,
  //   picklist_order_type,
  //   picklist_order_type_name,
  //   purchased_from,
  //   purchased_to,
  //   order_no,
  //   tracking_no,
  //   created_Date,
  //   to_created_Date,
  //   is_picklist,
  //   is_picklist_id,
  //   handlePage
  // ]);

  // xxxxxxxxxxxxxxxxxxxx

  const [isToggle, setIsToggle] = useState(false);

  const handleIsToggle = (val) => {
    setIsToggle(!val);
  };

  // Print Label Reducers
  const { print_label_notFound_order, loading: printing__loading } =
    useSelector((state) => state.PrintNotFoundOrderLabel);

  const [isPrinting, setIsPrinting] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);

  const [isActiveIndex, setIsActiveIndex] = useState("");

  const handlePrintLabel = (id, index) => {
    setIsActiveIndex(index);
    setIsPrinting(true);
    dispatch(
      PrintNotFoundOrderLabelActions("not_found_orders_label", token, [id])
    );
  };

  // QZ Tray code starts here
  useEffect(() => {
    // signed certificate for qz tray
    qz.security.setCertificatePromise(function (resolve, reject) {
      //Preferred method - from server
      fetch("digital-certificate.txt", {
        cache: "no-store",
        headers: { "Content-Type": "text/plain" },
      }).then(function (data) {
        data.ok ? resolve(data.text()) : reject(data.text());
      });

      //Alternate method 1 - anonymous
      //        resolve();  // remove this line in live environment

      //Alternate method 2 - direct
      resolve(
        "-----BEGIN CERTIFICATE-----\n" +
          "MIID+TCCAuGgAwIBAgIUFJx1QkZbDOHgHa+ec24vw3o1txIwDQYJKoZIhvcNAQEL\n" +
          "BQAwgYoxCzAJBgNVBAYTAklOMQ8wDQYDVQQIDAZQdW5qYWIxETAPBgNVBAcMCEx1\n" +
          "ZGhpYW5hMQ8wDQYDVQQKDAZTZWVrZXgxEzARBgNVBAsMClNldmVucm9ja3MxDDAK\n" +
          "BgNVBAMMA0dydjEjMCEGCSqGSIb3DQEJARYUZ3J2LndlYmRldkBnbWFpbC5jb20w\n" +
          "IBcNMjMwOTIzMDUwNjMxWhgPMjA1NTAzMTgwNTA2MzFaMIGKMQswCQYDVQQGEwJJ\n" +
          "TjEPMA0GA1UECAwGUHVuamFiMREwDwYDVQQHDAhMdWRoaWFuYTEPMA0GA1UECgwG\n" +
          "U2Vla2V4MRMwEQYDVQQLDApTZXZlbnJvY2tzMQwwCgYDVQQDDANHcnYxIzAhBgkq\n" +
          "hkiG9w0BCQEWFGdydi53ZWJkZXZAZ21haWwuY29tMIIBIjANBgkqhkiG9w0BAQEF\n" +
          "AAOCAQ8AMIIBCgKCAQEAjit0o5WvhQInxccqj7/Xtqgroq6X61gWI/gSQRfpbdMM\n" +
          "6U1MqGW8qzzR5bX+VBURXcdEFokpcxY3/oz17VjPQ0D/bMensZ+EqQubQqmM56zS\n" +
          "BdMlrITCoSbT+cNvA0gB4U4d8UQ58vzZHY0zj6lNFjCZuDfHO+DG+rD9EwjngZzU\n" +
          "+rS8jOb2y7xMu5tuncku/EeEESv1LndGhAmRAfidOUDAL4zbTdLQvz9zP8xcGzas\n" +
          "UhsTBfQ9r4e1b2LKgyIm08oY/eg2qjGWyfPfbrXrzsWpnyJwdHldHMUAAnvdcCxH\n" +
          "bcXSl1upy1wAq1WO4OpPkRlnRLqC09qGWnRAyU4fUQIDAQABo1MwUTAdBgNVHQ4E\n" +
          "FgQU/AtLKKkV14bzAAu99sUVO64c3DYwHwYDVR0jBBgwFoAU/AtLKKkV14bzAAu9\n" +
          "9sUVO64c3DYwDwYDVR0TAQH/BAUwAwEB/zANBgkqhkiG9w0BAQsFAAOCAQEAAA8B\n" +
          "aLZoOZ1w8SVNVDlpSSgq4+Py917Wp1Vp7D6GN6QL0PIO0Y+k6osFXQuWCNzjjSYp\n" +
          "BO7xnr4eHxSlJ9xh4s8d5RlVhCG+F8oRNeIAFsUjj4RLy/kvJWnJI5jcmjZCKhsS\n" +
          "zKGAiki6D4vhePGU+kzezOubGN0YJC5pVpwvTdJLXdiu77IwfTYSMRuxOu9wvhhk\n" +
          "oq/HKpBIYDr5b6frej6H9/o4mCN92qZulhF0bsYhDkvE/4S9cwDaX0i9bV7Ojt17\n" +
          "FCabjHwxjgycLavqJiXCFyfRoF8CyckAVzqMyuVcCP8/3wCM603KB41+LP+c6Hds\n" +
          "D1DnIqq9EEUabJvMyQ==\n" +
          "-----END CERTIFICATE-----\n"
      );
    });

    qz.security.setSignatureAlgorithm("SHA512"); // Since 2.1
    qz.security.setSignaturePromise(function (toSign) {
      return function (resolve, reject) {
        // Preferred method - from server
        axios
          .get(
            "https://erp.sevenrocks.in/app/webroot/qz-local/sign-message.php",
            {
              params: { request: toSign },
              headers: { "Content-Type": "text/plain" },
            }
          )
          .then(function (response) {
            if (response.status === 200) {
              resolve(response.data);
            } else {
              reject(response.data);
            }
          })
          .catch(function (error) {
            reject(error);
          });

        // Alternate method - unsigned
        // resolve(); // remove this line in the live environment
      };
    });
  }, [fetch]);

  // signed certificate for qz tray

  useEffect(() => {
    // Check if a connection is already open
    if (!qz.websocket.isActive()) {
      qz.websocket.connect().then(() => {
        return qz.printers.find().catch((err) => alert.error(err.message));
      });
    } else {
      // Handle the case when a connection is already open
      // You can choose to do nothing, show a message, or perform other actions here
      // alert.error("A connection with QZ Tray is already open.");
    }
  }, [alert]);
  // QZ Tray code ends here

  // useEffect(() => {
  //   let printer = localStorage.getItem("Printer");
  //   const handlePrintPDF = async () => {
  //     setIsLoaded(true);
  //     let pdfUrls = [];

  //     print_label_notFound_order?.data?.forEach((item) => {
  //       if (item.invoice) pdfUrls.push(item.invoice);
  //       if (item.label) pdfUrls.push(item.label);
  //     });

  //     if (print_label_notFound_order?.data?.length <= 0) {
  //       return alert.error("No PDF Found...");
  //     }

  //     console.log("PDF URLS: ", pdfUrls);

  //     if (qz.websocket.isActive()) {
  //       let config = qz.configs.create(
  //         printer !== null || printer !== undefined ? printer : null
  //       );

  //       try {
  //         await pdfUrls.reduce(async (previousPromise, pdfUrl) => {
  //           await previousPromise; // Wait for the previous promise to resolve
  //           await qz.print(config, [
  //             {
  //               type: "pixel",
  //               format: "pdf",
  //               flavor: "file",
  //               data: pdfUrl,
  //             },
  //           ]);
  //         }, Promise.resolve());

  //         setIsLoaded(false);
  //         setIsPrinting(false);
  //         // setIsActive(true);
  //         alert.success("All PDFs Printed Successfully...");
  //       } catch (err) {
  //         alert.error(err.message);
  //         setIsLoaded(false);
  //         setIsPrinting(false);
  //       }
  //     } else {
  //       alert.error("QZ Tray Is Not Connected, Please Select Printer First...");
  //       setIsLoaded(false);
  //       setIsPrinting(false);
  //     }
  //   };

  //   if (
  //     print_label_notFound_order &&
  //     print_label_notFound_order?.status === 1 &&
  //     isPrinting
  //   ) {
  //     handlePrintPDF();
  //   }
  // }, [print_label_notFound_order, isLoaded, alert, isPrinting]);

  // const printPDFs = useCallback(async () => {
  //   if (
  //     pdfUrls.includes(null) ||
  //     pdfUrls.includes(undefined) ||
  //     pdfUrls.length === 0
  //   ) {
  //     return alert.error("No PDF Found...");
  //   }
  //   // setLoading(true);

  //   if (qz.websocket.isActive()) {
  //     let config = qz.configs.create(
  //       printer !== null || printer !== undefined
  //         ? printer
  //         : props.selectedPrinter
  //     );

  //     try {
  //       await pdfUrls.reduce(async (previousPromise, pdfUrl) => {
  //         await previousPromise; // Wait for the previous promise to resolve
  //         await qz.print(config, [
  //           {
  //             type: "pixel",
  //             format: "pdf",
  //             flavor: "file",
  //             data: pdfUrl,
  //           },
  //         ]);
  //       }, Promise.resolve());

  //       // setLoading(false);
  //       // setIsActive(true);
  //       alert.success("All PDFs Printed Successfully...");
  //     } catch (err) {
  //       alert.error(err.message);
  //       // setLoading(false);
  //     }
  //   } else {
  //     alert.error("QZ Tray Is Not Connected, Please Select Printer First...");
  //     // setLoading(false);
  //   }
  // }, [
  //   alert,
  //   dispatch,
  //   multi_pdf,
  //   pdfUrls,
  //   pdf_all,
  //   printer,
  //   props.selectedPrinter,
  // ]);

  const [printer] = useState(localStorage.getItem("Printer") || null);

  useEffect(() => {
    let isRunning = false; // Prevent double execution

    const handlePrintPDF = async () => {
      if (isRunning) return; // Exit if already running
      isRunning = true;

      try {
        setIsLoaded(true);
        let pdfUrls = [];

        // Collect PDF URLs
        print_label_notFound_order?.data?.forEach((item) => {
          if (item.invoice) pdfUrls.push(item.invoice);
          if (item.label) pdfUrls.push(item.label);
        });

        if (pdfUrls.length === 0) {
          alert.error("No PDF Found...");
          setIsLoaded(false);
          setIsPrinting(false);
          return;
        }

        console.log("PDF URLs: ", pdfUrls);

        // Check if QZ Tray is active
        if (qz.websocket.isActive()) {
          const config = qz.configs.create(printer || null);

          for (const pdfUrl of pdfUrls) {
            await qz.print(config, [
              {
                type: "pixel",
                format: "pdf",
                flavor: "file",
                data: pdfUrl,
              },
            ]);
          }

          alert.success("All PDFs Printed Successfully...");
        } else {
          alert.error(
            "QZ Tray is not connected. Please select a printer first."
          );
        }
      } catch (err) {
        alert.error(`Error: ${err.message}`);
      } finally {
        setIsLoaded(false);
        setIsPrinting(false);
        isRunning = false; // Reset the flag
      }
    };

    // Trigger printing when conditions are met
    if (
      print_label_notFound_order?.status === 1 &&
      isPrinting &&
      print_label_notFound_order?.data?.length > 0
    ) {
      handlePrintPDF();
    }
    // eslint-disable-next-line
  }, [print_label_notFound_order, isPrinting, printer, alert, qz]);

  const [orderId, setOrderId] = useState([]);

  const handleGenerateBulk = () => {
    setIsPrinting(true);
    dispatch(
      PrintNotFoundOrderLabelActions("not_found_orders_label", token, orderId)
    );
  };

  useEffect(() => {
    if (orderList && orderList.length > 0) {
      const newOrderIds = [];
      orderList.forEach((i) => {
        if (!orderId.includes(i.id)) {
          newOrderIds.push(i.id);
        }
      });
      if (newOrderIds.length > 0) {
        setOrderId((prev) => [...prev, ...newOrderIds]);
      }
    }
    // eslint-disable-next-line
  }, [orderList]);

  console.log("Order Ids: ", orderId, orderList);

  return (
    <div
      className={`grid-sidebar-row ${isToggle && "active-grid-sidebar-row"}`}
    >
      <MetaData title={`Create Picklist`} />
      <div>
        <SideBar handleIsToggle={handleIsToggle} />
      </div>
      <div>
        <Header />
        <div className="wrapper-picker-container">
          {loading ? (
            <LoaderAlone />
          ) : (
            <>
              {status === 0 ? (
                <NoDataFromApi
                  height={true}
                  title="Something Went Wrong, Please Try Logout & ReLogin Again..."
                />
              ) : (
                <>
                  <Headline title="Create Picklist" hide={true} />

                  {/* dropdown */}
                  {/* dropdown */}
                  <div className="dropdown-grid-row">
                    <div>
                      <Dropdown
                        placeholder="Portal Name"
                        dropdown={portal_name_list && portal_name_list}
                        value={portal_name}
                        onData={handlePortalName}
                        noShow={true}
                        loading={allFilter_loading}
                      />
                    </div>
                    <div>
                      <Dropdown
                        placeholder="PickList Order Type"
                        dropdown={
                          picklist_order_type_list && picklist_order_type_list
                        }
                        value={picklist_order_type_name}
                        onData={handlePicklistOrderType}
                        noShow={true}
                        loading={allFilter_loading}
                      />
                    </div>
                    <div>
                      <DatePicker
                        className="date-picker"
                        placeholderText="From Purchased Date"
                        selected={purchased_from}
                        onChange={(date) => handlePurchasedOn(date)}
                      />
                    </div>
                    <div>
                      <DatePicker
                        className="date-picker"
                        placeholderText="To Purchased Date"
                        selected={purchased_to}
                        onChange={(date) => handleCreatedOn(date)}
                      />
                    </div>
                    <div>
                      <Dropdown
                        placeholder="Picklist order"
                        dropdown={mark_lost_not_found && mark_lost_not_found}
                        value={is_picklist_id}
                        onData={handleisPicklist}
                        noShow={true}
                        loading={allFilter_loading}
                      />
                    </div>
                    <div className="date_handover">
                      <input
                        type="text"
                        value={order_no}
                        onChange={(e) => handleOrderNo(e)}
                        placeholder="Order Code"
                        onKeyPress={handleKeyOrderNo}
                      />
                    </div>
                    <div className="date_handover">
                      <input
                        type="text"
                        onChange={(e) => handleTrackingNo(e)}
                        value={tracking_no}
                        placeholder="Tracking No"
                        onKeyPress={handleKeyTrackingNo}
                      />
                    </div>
                    <div className="date_handover">
                      <input
                        type="number"
                        min={0}
                        value={picklist_id}
                        onChange={(e) => handlePicklist_id(e)}
                        placeholder="Picklist Id"
                        onKeyPress={handlePicklistId}
                      />
                    </div>
                    <div>
                      <DatePicker
                        className="date-picker"
                        placeholderText="From Created On"
                        selected={created_Date}
                        onChange={(date) => handleCreatedOn_Date(date)}
                      />
                    </div>
                    <div>
                      <DatePicker
                        className="date-picker"
                        placeholderText="To Created On"
                        selected={to_created_Date}
                        onChange={(date) => handleCreatedOn_toDate(date)}
                      />
                    </div>
                    {/* New Filter */}
                    <div>
                      <Dropdown
                        placeholder="Label Generated"
                        dropdown={mark_lost_not_found && mark_lost_not_found}
                        value={is_label_generated_value}
                        onData={handleLabelGenerated}
                        noShow={true}
                        loading={allFilter_loading}
                      />
                    </div>
                    <div>
                      <Dropdown
                        placeholder="Bulk Label Generated"
                        dropdown={mark_lost_not_found && mark_lost_not_found}
                        value={is_bulk_label_generated_value}
                        onData={handleBulkLabelGenerated}
                        noShow={true}
                        loading={allFilter_loading}
                      />
                    </div>
                    <div>
                      <Dropdown
                        placeholder="Is Handover done?"
                        dropdown={mark_lost_not_found && mark_lost_not_found}
                        value={is_handover_done_name}
                        onData={handleHandoverDone}
                        noShow={true}
                        loading={allFilter_loading}
                      />
                    </div>
                    <div>
                      <Dropdown
                        placeholder="Is Order Valid?"
                        dropdown={mark_lost_not_found && mark_lost_not_found}
                        value={is_valid_order_name}
                        onData={handleValidOrder}
                        noShow={true}
                        loading={allFilter_loading}
                      />
                    </div>
                    <div>
                      <Dropdown
                        placeholder="Courier Name"
                        dropdown={carrier_name_list && carrier_name_list}
                        value={mapped_carrier_name}
                        onData={handleCourierName}
                        noShow={true}
                        loading={allFilter_loading}
                      />
                    </div>
                    <div>
                      <Dropdown
                        placeholder="Not Found Label Gen."
                        dropdown={mark_lost_not_found && mark_lost_not_found}
                        value={
                          parseInt(is_not_found) === 0
                            ? "No"
                            : parseInt(is_not_found) === 1
                            ? "Yes"
                            : ""
                        }
                        onData={handleIsNotFound}
                        noShow={true}
                        loading={allFilter_loading}
                      />
                    </div>
                    {/* New Filter */}
                    <div>
                      <div className="clear-filter">
                        <Button onClick={() => resteFilter()}>
                          Reset Filter
                        </Button>
                      </div>
                    </div>
                  </div>
                  {/* dropdown */}
                  {/* dropdown */}

                  {/* table */}
                  <div className="table-wrapper-pagination">
                    <div className="table-container-pagination">
                      <div className="header-limit-checkbox">
                        <div>
                          <div>
                            <input
                              type="checkbox"
                              checked={
                                ActiveOrders && ActiveOrders?.length > 0
                                  ? ActiveOrders.every((i) =>
                                      i.is_pick_list === 0 &&
                                      order_ids.includes(i.id)
                                        ? true
                                        : false
                                    )
                                  : false
                              }
                              readOnly
                              id="checkbox"
                            />
                            <label
                              onClick={() => handleIndexAll()}
                              htmlFor="checkbox"
                            ></label>
                          </div>
                          <p className="lable-select-all">Select all</p>
                          <Button
                            disabled={create_loading ? true : false}
                            type="submit"
                            onClick={handlePicklistCreate}
                            className="create-picklist-requirements"
                          >
                            {create_loading ? (
                              <Loader isSmall={true} />
                            ) : (
                              "Create"
                            )}
                          </Button>

                          {/* Bulk Label Generated Not Found Order */}
                          <Button
                            disabled={is_not_found ? false : true}
                            type="submit"
                            className="generate-bulk-label"
                            onClick={handleGenerateBulk}
                          >
                            {printing__loading || isLoaded ? (
                              <Loader isSmall={true} />
                            ) : (
                              "Not Found Bulk Label"
                            )}
                          </Button>
                          {/* Bulk Label Generated Not Found Order */}
                        </div>
                        <div className="result-flex-grid">
                          <p className="result-out">
                            *Result {page} in{" "}
                            {filtersTrue
                              ? filterTotal_pages
                              : list_orders && list_orders?.total_pages}
                          </p>
                          <div className="limit">
                            <input
                              type="text"
                              name="limit"
                              id="limit"
                              value={limit}
                              onKeyPress={handleLimit}
                              onChange={(e) => handleLimitTag(e)}
                            />
                          </div>
                        </div>
                      </div>
                      {filter_Loading ? (
                        <LoaderAlone />
                      ) : (
                        <DataTable
                          orderTable={orderList && orderList}
                          header={orderTable}
                          onSelect={handleIndex}
                          isIndexSelectAll={order_ids}
                          isOk={isOk}
                          handleOrder={handlePicklistDetails}
                          avoidUnsedApiCall={false}
                          is_not_found={is_not_found}
                          handlePrintLabel={handlePrintLabel}
                          printLabelLoading={isLoaded}
                          isActiveIndex={isActiveIndex}
                        />
                      )}
                    </div>
                  </div>

                  <div className="rendering-pagination">
                    <Pagination
                      uniqueKey={`ScreenA-${page}`}
                      page={page}
                      isLoading={isLoading ? isLoading : filter_Loading}
                      totalPage={
                        filtersTrue
                          ? filterTotal_pages
                          : list_orders && list_orders?.total_pages
                      }
                      onPageChange={handlePage}
                      onHandleCheckbox={handleNull}
                    />
                    {/* <div>
                      <div className="limit">
                        <input
                          type="text"
                          name="limit"
                          id="limit"
                          value={limit}
                          onKeyPress={handleLimit}
                          onChange={(e) => handleLimitTag(e)}
                        />
                      </div>
                    </div> */}
                  </div>
                </>
              )}
            </>
          )}
        </div>
      </div>
      {renderModal()}
    </div>
  );
};

export default CreateOrder;
